<template>
  <HandleDataForms :api-source="mongo" :data-source="database" title="Formulario Gratuidad CFT" collection="cft_form_gratuidad" template-table="TableGratuidadForm" template-table-modal="TableGratuidadModal" :data-excel="dataExcel" />
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
import moment from 'moment'
import HandleDataForms from '@/components/HandleDataForms.vue'

export default {
  components: {
    HandleDataForms,
  },
  setup() {
    // eslint-disable-next-line no-unused-vars
    const store = useStore()
    const database = computed(() => store.getters.getProduccion)
    //const mongo = computed(() => store.getters.getMongoURL)
    const mongo = 'https://api.idastage.com/api/'

    const startDate = computed(() => moment().startOf('week').format('YYYY-MM-DD'))
    const endDate = computed(() => moment().format('YYYY-MM-DD'))

    let dataExcel = {
      fields: {
        pid: 'pid',
        fecha: 'fecha',
        tipo_identificacion: 'tipo_identificacion',
        rut: 'rut',
        pasaporte: 'pasaporte',
        nombre: 'nombre',
        apellido: 'apellido',
        email: 'email',
        codigo_telefono: 'codigo_telefono',
        telefono: 'telefono',
        mensaje: 'mensaje',
      },
      query: {
        collection: 'cft_form_gratuidad',
        fecha_inicio: startDate.value + ' 00:00:00',
        fecha_fin: endDate.value + ' 23:59:59',
        format_date_field: 'ISO',
      },
    }

    return {
      mongo,
      dataExcel,
      database
    }
  },
}
</script>
